<template>
    <div class="mall-banner">
        <el-image
            :src="require('@/assets/images/video/photographer_bg.jpg')"
            class="mall-banner-bgm"
            fit="cover">
            <!-- 加载中样式 -->
            <template v-slot:placeholder>
                <div class="image-slot">
                    <i class="el-icon-loading"></i>
                </div>
            </template>
            <!-- 加载失败样式 -->
            <template v-slot:error>
                <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </template>
        </el-image>
        <!-- 前往 -->
        <div class="mall-banner-do">
            <div class="container mall-banner-do-box">
                <!-- go -->
                <router-link :to="routerPath"
                    class="mall-banner-do-go">
                    {{routerName}}
                    <i class="el-icon-d-arrow-right"></i>
                </router-link>
                <!-- search -->
                <div class="mall-banner-do-search">
                    <search-component>
                    </search-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            routerName: String,
            routerPath: String
        },
        components: {
            searchComponent: () => import("@/components/imageMall/searchComponent")
        }
    }
</script>

<style scoped>
.mall-banner{
    position: relative;
    height: 200px;
}
.mall-banner-bgm{
    width: 100%;
    height: 100%;
}
.mall-banner-do{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
}

.mall-banner-do-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mall-banner-do-go{
    display: inline-block;
    width: 300px;
    padding: 10px 0;
    background-color: var(--color);
    border-radius: 30px;
    text-align: center;
    letter-spacing: 2px;
    font-size: 16px;
    color: #fff;
}
.mall-banner-do-go > i{
    font-size: 20px;
}
.mall-banner-do-search{
    flex: 1;
    margin-left: 100px;
}
</style>